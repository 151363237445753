var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"wrapper"},[_c('Backlink',{attrs:{"title":"service"}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"content__page"},[_c('div',{staticClass:"service__container"},[_c('router-link',{staticClass:"service",attrs:{"to":{
              name: 'Mortgage registration',
              params: {
                backlink: '/mortgage',
              },
            },"tag":"button"}},[_c('div',{staticClass:"service__title"},[_vm._v(_vm._s(_vm._f("localize")("new_mortgage")))])]),_c('router-link',{staticClass:"service service_second",attrs:{"to":{
              name: 'Refinance mortgage registration',
              params: {
                backlink: '/mortgage',
              },
            },"tag":"button"}},[_c('div',{staticClass:"service__title"},[_vm._v(" "+_vm._s(_vm._f("localize")("refinance_mortgage"))+" ")])])],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }