<template>
  <div class="page">
    <div class="wrapper">
      <Backlink title="service" />
      <div class="content">
        <div class="content__page">
          <div class="service__container">
            <router-link
              :to="{
                name: 'Mortgage registration',
                params: {
                  backlink: '/mortgage',
                },
              }"
              class="service"
              tag="button"
            >
              <div class="service__title">{{ "new_mortgage" | localize }}</div>
            </router-link>
            <router-link
              :to="{
                name: 'Refinance mortgage registration',
                params: {
                  backlink: '/mortgage',
                },
              }"
              class="service service_second"
              tag="button"
            >
              <div class="service__title">
                {{ "refinance_mortgage" | localize }}
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'

export default {
  name: 'Mortgage',
  components: { Backlink }
}
</script>
